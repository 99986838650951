import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from 'src/app/_core/services/loader.service';
import { SecurityUtil } from 'src/app/_core/utils/security.util';
import { LoginModel } from 'src/app/auth/models/login.model';
import { ResultV1Model } from 'src/app/shared/models/result-v1.model';
import Swal from 'sweetalert2';
import { TratamentoErrosHttpErrorResponseService } from 'src/app/shared/services/tratamento-erros-http-error-response.service';
import { RegistrationComponent } from '../registration/registration.component';
import { LoginService } from '../services/login.service';
import { BehaviorSubject } from 'rxjs';
import { AccountV1Model } from '../models/account-v1.model';
import { LoginAccessV1Model } from '../models/login-access-v1.model';

@Injectable({
  providedIn: 'root',
})

export class LoginFuncoesCompartilhadasV1Service {
  //variáveis de ambiente
  static isRedefinirSenhaSub = new BehaviorSubject<boolean>(false);
  private isRedefinirSenha: boolean = false;

  //variáveis identificacao de dispositivo
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktop: boolean = false;


  constructor(
    public modalAtivo: NgbActiveModal,
    private readonly loginService: LoginService,
    private readonly loaderService: LoaderService,
    private modalService: NgbModal,
    public route: Router,
  ) {
    //Setando o tipo de dispositivo
    this.isMobile = window.innerWidth < 768;
    this.isTablet = (window.innerWidth >= 768 && window.innerWidth < 992);
    this.isDesktop = window.innerWidth >= 992;

    LoginFuncoesCompartilhadasV1Service.isRedefinirSenhaSub.subscribe(
      op => this.isRedefinirSenha = op
    )
  }

  fecharModal() {
    LoginFuncoesCompartilhadasV1Service.isRedefinirSenhaSub.next(false);
    this.modalAtivo.close();
  }

  /**
   * Função utilizada para verificar se há token de login no localStorage
   */
  public verificarSeUsuarioEstaLogado() {
    if (SecurityUtil.hasToken()) {
      /**Se tiver o token, é feito uma validação do token para verificar se pode redirecionar o usuário para o painel da conversão */
      this.loginService.refreshtoken().subscribe({
        next: resultado => {
          if (resultado.success) {
            /**
             * Se o token for validado, o novo token retornado será setado no localStorage
             * bem como o userAccount (AccountV1Model) será setado no sessionStorage
             */
            SecurityUtil.setToken(resultado.data.token)
            this.getUserAccById();
            this.route.navigate([`/assinantes/conversor`]);
          } else {
            /**
             * Caso ocorra algum erro durante a validação do token, será feito a limpa
             * do localStorage e o usuário terá que fazer o login na plataforma, informando os dados
             */
            SecurityUtil.clear();
            this.loaderService.stopLoader();
          }
        },
        error: () => {
          /**
           * Caso ocorra algum erro durante a validação do token, será feito a limpa
           * do localStorage e o usuário terá que fazer o login na plataforma, informando os dados
           */
          SecurityUtil.clear();
          this.loaderService.stopLoader();
        }
      }
      );
    }
  }

  /**
   * Função utilizada para fazer login na plataforma
   * @param dadosLogin Os dados para acesso, sendo eles email e senha
   * @param isFinalizarCadastro Opcional, caso seja true, ao invés de ser direcionado para o painelConversor,
   * o usuário será direcionado para o componente Contratar
   */
  public async fazerLogin(dadosLogin: LoginModel, isFinalizarCadastro?: boolean, isAutoRegistro?: boolean, routeUrl?: string) {
    await this.loginService
      .autenticar(dadosLogin)
      .subscribe({
        next: (resultado: ResultV1Model) => {
          if (resultado.success) {
            const account: LoginAccessV1Model = resultado.data.account;

            !account.isAssinatura ? account.isAssinatura = false : null;
            !account.isManager ? account.isManager = false : null;
            !account.isParceiro ? account.isParceiro = false : null;
            !account.isTeste ? account.isTeste = false : null;
            !account.isReteste ? account.isReteste = false : null;

            try {
              /**Setando o token e o account no localStorage */
              SecurityUtil.set(
                account,
                resultado.data.token,
              );

              /**Setando o account do usuário a logar */
              this.getUserAccById();

              /**Avaliando qual processo chamou o componente de login */
              if (isFinalizarCadastro!) {
                /**
                 * Se for o processo de finalização de cadastro, 
                 * o usuário é redirecionado para assinantes/contratar
                 * para que possa finalizar a contratação, 
                 * com isto, a variável routeUrl recebe a rota para contratação
                 */
                routeUrl = '/assinantes/contratar';
              } else {
                /**
                 * Se não for, o usuário será redirecionado para o painel do conversor, 
                 * com isto, a variável routeUrl recebe a rota para o painel
                 */
                routeUrl = '/assinantes/conversor';
              }

              /** Navegando para rota definida */
              this.route.navigate([`${routeUrl}`]);
            } catch (error) {
              //em caso de qualquer erro durante a tentativa de login, será feito uma limpa no localStorage, onde é setado o ofx-account/ofx-token
              localStorage.clear();
            }

          } else {
            //tratamento de erro, caso o resulto.success == false
            Swal.fire(
              resultado.titulo,
              resultado.message,
              'error'
            );
            this.loaderService.stopLoader();
          };
        },
        error: (err) => {
          console.log('err');
          console.log(err);
          /**Tratamento dos erros que é retornado pela API */
          this.loaderService.stopLoader();
          if (err.error.error == 'expiredtest') {
            /**Se o error == 'expiredtest' indica que o período de teste expirou */
            this.alertaFazerResteste(dadosLogin, err.error);
          } else if (err.error.error == 'expiredretest') {
            /**Se o error == 'expiredretest' indica que o período de reteste expirou */
            this.alertaContratar(err.error, dadosLogin);
          } else if (err.error.error == 'expiredsignature') {
            /**Se o error == 'expiredsignature' indica que o período da assinatura expirou e não houve a renovação */
            this.alertaRenovarContratacao(err.error, dadosLogin);
          } else if (err.error.error == 'notaccesskey') {
            /**Se o error == 'notaccesskey' indica que a senha está incorreta */
            this.alertaResetarSenha(err.error);
          } else if (err.error.error == 'notfound') {
            /**Se o error == 'notaccesskey' indica que a senha está incorreta */
            this.alertaEmailNaoLocalizado(err.error);
          } else {
            TratamentoErrosHttpErrorResponseService.tratarErro(err);
          }
        }
      });
  }

  /**Caso o usuário já tenha utilizado o teste e o reteste e a data de avaliação tenha expirado */
  private alertaFazerResteste(dadosLogin: LoginModel, error: ResultV1Model) {
    Swal.fire({
      title: error.titulo,
      text: `
          Teste expirado! Seu acesso teste expirou. Contrate nossos serviços ou estenda o teste por mais 5 dias.
        `,
      showCancelButton: false,
      showConfirmButton: true,
      showDenyButton: true,
      denyButtonText: 'Contratar',
      confirmButtonText: 'Estender período de teste'
    }).then(
      async (resultado) => {
        if (resultado.isConfirmed) {
          /**
           * Se o usuário clicar no botão Estender Período de Teste 
           * será feito um post na rota para estender o período de avaliação
           * e a função para fazer login será acionada
          */
          if (error.data!) {
            await this.loginService.estenderPeriodoAvaliacao(error.data).subscribe({
              next: (resultado) => {
                Swal.fire(
                  resultado.titulo,
                  resultado.message,
                  resultado.success ? 'success' : 'error'
                ).then(() => {
                  this.fazerLogin(dadosLogin);
                });
              },
              error: (err) => {
                TratamentoErrosHttpErrorResponseService.tratarErro(err);
              }
            })
          }
        } else if (resultado.isDenied) {
          /**Se o usuário clicar em 'Contratar' */
          if (error.data!) {
            /**
             * Será feito um post na rota de estender o período de avaliação,
             * para que o usuário logue na plataforma e seja direcionado para
             * o componente Contratar
             */
            await this.loginService.estenderPeriodoAvaliacao(error.data).subscribe({
              next: () => {
                this.fazerLogin(dadosLogin, true);
              },
              error: (err) => {
                TratamentoErrosHttpErrorResponseService.tratarErro(err);
              }
            })
          }
        }
      }
    );
  }

  /**Caso o usuário já tenha utilizado o teste e o reteste e a data de avaliação tenha expirado */
  private alertaContratar(err: ResultV1Model, dadosLogin: LoginModel) {
    Swal.fire({
      title: err.titulo,
      text: err.message,
      showConfirmButton: true,
      confirmButtonText: 'Contratar'
    }).then(async () => {
      /**Ao clicar na opção contratar */
      await this.loginService.getContaByIdUserNaoAutenticado(err.data.codigo).subscribe({
        next: resultado => {
          /**Será feito um get do user, com o idAccount que é retornado pelo erro no login */
          sessionStorage.setItem('userAccount', btoa(JSON.stringify(resultado.data)));

          //Os dados para logar na plataforma, após a renovação da assinatura é setado no armazenamento de sessão
          sessionStorage.setItem('dadosLogin', btoa(JSON.stringify(dadosLogin)));

          /**O usuário então será redirecionado para a página de contratação, sem fazer login na plataforma */
          this.route.navigate(['/nao-assinantes/contratar']);
        },
        /**Em caso de erros, será feito o tratamento */
        error: err => TratamentoErrosHttpErrorResponseService.tratarErro(err)
      });
    });
  }

  /**Função utilizada quando, ao logar, não é localizado no banco de dados o email digitado pelo usuário */
  private alertaEmailNaoLocalizado(err: ResultV1Model) {
    Swal.fire({
      title: err.titulo,
      text: err.message,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Criar conta'
    }).then(
      (result) => {
        /**Se o usuário optar por 'Criar conta' */
        if (result.isConfirmed) {
          //Será redierecionado para o componente AutoRegistro
          this.route.navigate(['/auth/registro']);
        }
      }
    );
  }

  /**Função utilizada quando, ao logar, é retornado que a assinatura expirou */
  private alertaRenovarContratacao(err: ResultV1Model, dadosLogin: LoginModel) {
    Swal.fire({
      title: err.titulo,
      text: err.message,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: 'Renovar contratação'
    }).then(async result => {
      /**Se o usuário optar por 'Renovar contratação' */
      if (result.isConfirmed) {
        /**Será feito um get no account do usuário, com o idAccount retornado pelo erro no login */
        await this.loginService.getContaByIdUserNaoAutenticado(err.data._idAccount).subscribe({
          next: resultado => {
            console.log('resultado')
            console.log(resultado)
            //O account então é setado no armazenamento de sessão (sessionStorage)
            sessionStorage.setItem('userAccount', btoa(JSON.stringify(resultado.data)));

            //Os dados para logar na plataforma, após a renovação da assinatura é setado no armazenamento de sessão
            sessionStorage.setItem('dadosLogin', btoa(JSON.stringify(dadosLogin)));

            //O usuário é redirecionado para o componente de contratação, sem fazer login na plataforma
            this.route.navigate(['/nao-assinantes/contratar']);
          },
          //Em caso de erros, será feito o tratamento do erro
          error: err => TratamentoErrosHttpErrorResponseService.tratarErro(err)
        });
      }
    });
  }

  private alertaResetarSenha(err: ResultV1Model) {
    Swal.fire({
      title: err.titulo,
      text: err.message,
    });
  }

  private abrirModalCriarContaAvaliacao() {
    let modalRef = this.modalService.open(
      RegistrationComponent,
      {
        modalDialogClass: 'modal-dialog',
        size: 'md',
        scrollable: true,
        centered: true,
        backdrop: 'static',
        keyboard: true,
      }
    )

    modalRef.componentInstance.planoSelecionado = undefined;
  }

  public redefinirSenha() {
    LoginFuncoesCompartilhadasV1Service.isRedefinirSenhaSub.next(true);
  }

  /**Função utilizada para setar o Account do usuário no sessionStorage e emitir o valor no subject userLogado */
  private async getUserAccById() {
    await this.loginService.getContaById().subscribe({
      next: resultado => {
        sessionStorage.setItem('userAccount', btoa(JSON.stringify(resultado.data)))
        LoginFuncoesCompartilhadasV1Service.userLogado.next(resultado.data)
      }
    })
  }

  //inicio subjects
  public static userLogado = new BehaviorSubject<AccountV1Model | undefined>(undefined);

  public static reiniciarUserLogado() {
    LoginFuncoesCompartilhadasV1Service.userLogado = new BehaviorSubject<AccountV1Model | undefined>(undefined);
  }
  //fim subjects
}
