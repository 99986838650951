import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { BehaviorSubject } from 'rxjs';
import { LoaderComponent } from 'src/app/_core/loader/loader.component';
import { SecurityUtil } from 'src/app/_core/utils/security.util';
import { LoginFuncoesCompartilhadasV1Service } from 'src/app/auth/login/login.component';
import { LoginAccessV1Model } from 'src/app/auth/models/login-access-v1.model';
import { LoginModel } from 'src/app/auth/models/login.model';
import { RegistrationComponent } from 'src/app/auth/registration/registration.component';
import { LoginService } from 'src/app/auth/services/login.service';
import { PlanoSelecionadoV1Model } from 'src/app/pages/models/plano-selecionado-v1.model';
import { ResultV1Model } from 'src/app/shared/models/result-v1.model';
import { TratamentoErrosHttpErrorResponseService } from 'src/app/shared/services/tratamento-erros-http-error-response.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-obter-user-account',
  standalone: true,
  imports: [
    FeatherModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
  ],
  templateUrl: './modal-obter-user-account.component.html',
  styles: ``
})
export class ModalObterUserAccountComponent {
  //variáveis de ambiente
  loginForm: FormGroup;
  mostrar: boolean = false;
  email: string;
  accesskey: string;

  //variáveis a serem preenchidas na chamada do modal
  planoSelecionado: PlanoSelecionadoV1Model;

  //variáveis de comunicação
  isLoginConcluido = new BehaviorSubject<boolean>(false);

  constructor(
    public modalAtivo: NgbActiveModal,
    private readonly loginService: LoginService,
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) {
    this.loginForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      password: ['',
        Validators.required
      ],
    });
  }

  async onSubmit() {
    this.email = this.loginForm.value['email'];
    this.accesskey = this.loginForm.value['password'];
    const dadosLogin = new LoginModel(
      this.email,
      this.accesskey
    );

    this.fazerLogin(dadosLogin);
  }

  fecharModal() {
    this.modalAtivo.close();
  }

  /**
   * Faz o login do usuário na plataforma.
   *
   * Esta função realiza os seguintes passos:
   * 1. Autentica o usuário com os dados de login fornecidos.
   * 2. Inicializa os parâmetros de avaliação do tipo de conta, 
   * evitando erros de 'can not read properties of undefined'.
   * 3. Armazena o token e a conta do usuário no localStorage.
   * 4. Obtém os dados da conta do usuário e fecha o modal de login.
   * 5. Lida com diferentes erros que podem ocorrer durante o processo de login, 
   * incluindo períodos expirados de teste, reteste, assinatura e erros de senha.
   *
   * @param {LoginModel} dadosLogin - Os dados de login do usuário.
   * @method
   */
  async fazerLogin(dadosLogin: LoginModel) {
    await this.loginService.autenticar(dadosLogin).subscribe({
      next: (resultado: ResultV1Model) => {
        if (resultado.success) {
          const account: LoginAccessV1Model = resultado.data.account;
          /**
           * Inicilizando os parâmetros de avaliação do tipo de conta, 
           * que podem não ser retornados pelo processo de login
           * impedindo possíveis erros de 'can not read properties of undefined'
           */
          !account.isAssinatura ? account.isAssinatura = false : null;
          !account.isManager ? account.isManager = false : null;
          !account.isParceiro ? account.isParceiro = false : null;
          !account.isTeste ? account.isTeste = false : null;
          !account.isReteste ? account.isReteste = false : null;

          try {
            /**Setando o token e o account no localStorage */
            SecurityUtil.set(
              account,
              resultado.data.token,
            );

            /**Setando o account do usuário a logar */
            this.getUserAccById();
          } catch (error) {
            //em caso de erros, será feito a limpeza dos dados salvos no localStorage
            localStorage.clear();
          }
        } else {
          Swal.fire(
            resultado.titulo,
            resultado.message,
            'error'
          );
        };
      },
      error: (err) => {
        console.log('erro ao logar')
        console.log(err)
        const idAccount = err.error.data?._idAccount!
          ? err.error.data?._idAccount!
          : (
            err.error.data?.codigo!
              ? err.error.data?.codigo!
              : ''
          );
        if (err.error.error == 'expiredtest') {
          /**Se o error == 'expiredtest' indica que o período de teste expirou */
          this.estenderPeriodoAvaliacaoELogar(dadosLogin, idAccount);
        } else if (err.error.error == 'expiredretest') {
          /**
           * Se o error == 'expiredretest' 
           * indica que o período de reteste expirou 
           * Será feito get account by id que será setado
           * no sessionStorage
           */
          this.setarUserAccountNoSession(idAccount);
        } else if (err.error.error == 'expiredsignature') {
          /**
           * Se o error == 'expiredsignature' indica que a assinatura expirou
           * No retorno, ele traz o _idAccount do usuário, 
           * então, esse _idAccount será emitido para o FormContratarAssinatura
           */
          this.setarUserAccountNoSession(idAccount);
        } else if (err.error.error == 'notaccesskey') {
          /**Se o error == 'notaccesskey' indica que a senha está incorreta */
          this.alertaResetarSenha(err.error);
        } else if (err.error.error == 'notfound') {
          /**Se o error == 'notaccesskey' indica que a senha está incorreta */
          this.alertaEmailNaoLocalizado(err.error);
        } else {
          TratamentoErrosHttpErrorResponseService.tratarErro(err);
        }
      }
    });
  }

  /**
   * Seta a conta do usuário no sessionStorage.
   *
   * Esta função realiza os seguintes passos:
   * 1. Obtém a conta do usuário não autenticado pelo ID.
   * 2. Armazena a conta do usuário no sessionStorage.
   * 3. Emite um evento indicando que o login foi concluído.
   * 4. Fecha o modal de login.
   *
   * @param {string} idAccount - O ID da conta do usuário.
   * @method
   */
  setarUserAccountNoSession(idAccount: string) {
    this.loginService.getContaByIdUserNaoAutenticado(idAccount).subscribe({
      next: resultado => {
        sessionStorage.setItem('userAccount', btoa(JSON.stringify(resultado.data)));
        this.isLoginConcluido.next(true);
        this.fecharModal();
      },
      error: err => TratamentoErrosHttpErrorResponseService.tratarErro(err)
    });
  }

  /**
   * Estende o período de avaliação e faz o login do usuário.
   *
   * Esta função realiza os seguintes passos:
   * 1. Estende o período de avaliação para a conta do usuário.
   * 2. Após a extensão do período de avaliação, realiza o login do usuário novamente.
   *
   * @param {LoginModel} dadosLogin - Os dados de login do usuário.
   * @param {string} idAccount - O ID da conta do usuário.
   * @method
   */
  async estenderPeriodoAvaliacaoELogar(dadosLogin: LoginModel, idAccount: string) {
    await this.loginService.estenderPeriodoAvaliacao(idAccount).subscribe({
      next: () => {
        this.fazerLogin(dadosLogin);
      },
      error: (err) => {
        TratamentoErrosHttpErrorResponseService.tratarErro(err);
      }
    })
  }

  /**
   * Exibe um alerta quando o e-mail não é encontrado.
   *
   * Esta função utiliza o SweetAlert2 para exibir um modal de alerta ao usuário
   * informando que o e-mail não foi localizado. Oferece a opção de cancelar ou criar uma conta.
   * Se o usuário optar por criar uma conta, o modal de login é fechado e o modal de criação de conta é aberto.
   *
   * @param {ResultV1Model} err - O erro retornado contendo título e mensagem.
   * @method
   */
  alertaEmailNaoLocalizado(err: ResultV1Model) {
    Swal.fire({
      title: err.titulo,
      text: err.message,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Criar Conta'
    }).then(
      (result) => {
        if (result.isConfirmed) {
          this.modalAtivo.close();
          this.abrirModalCriarContaAvaliacao();
        }
      }
    );
  }

  /**
   * Exibe um alerta para redefinição de senha.
   *
   * Esta função utiliza o SweetAlert2 para exibir um modal de alerta ao usuário
   * informando que a senha está incorreta e oferecendo a possibilidade de redefinição.
   *
   * @param {ResultV1Model} err - O erro retornado contendo título e mensagem.
   * @method
   */
  alertaResetarSenha(err: ResultV1Model) {
    Swal.fire({
      title: err.titulo,
      text: err.message,
    });
  }

  /**
   * Abre o modal de criação de conta para avaliação.
   *
   * Esta função abre um modal de criação de conta utilizando o `NgbModal`.
   * O modal é configurado para ser centrado, rolável e com um backdrop estático.
   *
   * @method
   */
  abrirModalCriarContaAvaliacao() {
    let modalRef = this.modalService.open(
      RegistrationComponent,
      {
        modalDialogClass: 'modal-dialog',
        size: 'md',
        scrollable: true,
        centered: true,
        backdrop: 'static',
        keyboard: true,
      }
    )

    modalRef.componentInstance.planoSelecionado = this.planoSelecionado;
  }

  /**
   * Função utilizada para setar o Account 
   * do usuário no sessionStorage e emitir 
   * o valor no subject userLogado 
   */
  private async getUserAccById() {
    await this.loginService.getContaById().subscribe({
      next: resultado => {
        sessionStorage.setItem('userAccount', btoa(JSON.stringify(resultado.data)))
        LoginFuncoesCompartilhadasV1Service.userLogado.next(resultado.data)
        this.isLoginConcluido.next(true);
        this.fecharModal();
      }
    })
  }
}
