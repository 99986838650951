import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, OnInit, OnChanges } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { SharedModule } from "./shared/shared.module";
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from './_core/services/loader.service';
import { HttpClientModule } from '@angular/common/http';
import { SecurityUtil } from './_core/utils/security.util';
import { TratamentoErrosHttpErrorResponseService } from './shared/services/tratamento-erros-http-error-response.service';
import { AuthGuard } from './_core/guards/auth.guard';
import { CpfPipe } from './shared/pipe/cpf.pipe';
import { CnpjPipe } from './shared/pipe/cnpj.pipe';
import { PagesModule } from './pages/pages.module';
import { TelefonePipe } from './shared/pipe/telefone.pipe';
import { NaoAssinantesModule } from './routes/nao-assinantes/nao-assinantes.module';
import { LoginFuncoesCompartilhadasV1Service } from './auth/login/login.component';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    FeatherModule.pick(allIcons),
    ScrollToModule.forRoot(),
    NgbDropdownModule,
    NgbModule,
    HttpClientModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    FlatpickrModule.forRoot(),
    SharedModule,
    SweetAlert2Module.forRoot(),
    PagesModule,
    NaoAssinantesModule
  ],
  exports: [
    FeatherModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    SecurityUtil,
    LoaderService,
    TratamentoErrosHttpErrorResponseService,
    AuthGuard,
    CpfPipe,
    CnpjPipe,
    TelefonePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule{}
