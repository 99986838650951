import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { LoaderService } from 'src/app/_core/services/loader.service';
import { CpfPipe } from 'src/app/shared/pipe/cpf.pipe';
import { FormReativoService } from 'src/app/shared/services/form-reativo.service';
import { LoginService } from '../services/login.service';
import { TratamentoErrosHttpErrorResponseService } from 'src/app/shared/services/tratamento-erros-http-error-response.service';
import Swal from 'sweetalert2';
import { NewAccountV1Interface } from '../models/account-crud-v1-interface';
import { LoginFuncoesCompartilhadasV1Service } from '../login/login.component';
import { LoginModel } from '../models/login.model';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  standalone: true,
  imports: [
    FeatherModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
})
export class RegistrationComponent {
  //variáveis de ambiente
  formNovaConta: FormGroup;
  isLiEConcordo: boolean = false;

  //variáveis a serem preenchidas ao chamar o modal
  planoSelecionado?: string;

  constructor(
    public modalAtivo: NgbActiveModal,
    public formReativo: FormReativoService,
    public cpfPipe: CpfPipe,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private loginService: LoginService,
    private loginFuncoes: LoginFuncoesCompartilhadasV1Service
  ) {
    this.formNovaConta = fb.group(this.montarForm());
  }

  montarForm() {
    return {
      nome: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email
        ])
      ],
      celular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ])
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)
        ])
      ],
      passwordConfirm: [
        '',
        Validators.compose([
          Validators.required
        ])
      ]
    }
  }

  get controls() {
    return this.formNovaConta.controls;
  }

  criarContaEFazerLogin() {
    this.loaderService.startLoader(false);
    const nome: string = this.controls['nome'].value;

    const conta: NewAccountV1Interface = {
      form: {
        opcao: this.planoSelecionado ? this.planoSelecionado : "Não Definido",
        nome: nome,
        email: this.controls['email'].value,
        accesskey: this.controls['password'].value,
        celular: this.controls['celular'].value
      },
    }

    if (this.planoSelecionado!) {
      this.criarPreContaEDirecionarParaFinalizarOCadastro(conta)
    } else {
      this.criarNovaContaTeste(conta)
    }
  }

  fecharModalEExcluirPlanoSelecionado() {
    this.modalAtivo.close();
    sessionStorage.removeItem('planoSelecionado');
  }

  /**
   * Função utilizada quando chamado pelo componente ValoresV1*
   */
  async criarPreContaEDirecionarParaFinalizarOCadastro(
    conta: NewAccountV1Interface
  ) {
    await this.loginService
      .criarNovaContaAvaliacao(conta)
      .subscribe({
        next: resultado => {
          this.loaderService.stopLoader();
          if (resultado.success) {
            Swal.fire(
              'Conta Criada!',
              'Conta criada com sucesso! Você será redirecionado para finalizar a contratação da assinatura.',
              'success'
            ).then(() => {
              const dadosLogin = new LoginModel(
                this.formNovaConta.value['email'],
                this.formNovaConta.value['password']
              )
              this.loginFuncoes.fazerLogin(dadosLogin, true);
            });
          } else {
            this.loaderService.stopLoader();
            Swal.fire(
              resultado.titulo,
              resultado.message,
              'error'
            );
          }
        },
        error: err => {
          this.loaderService.stopLoader();
          TratamentoErrosHttpErrorResponseService.tratarErro(err);
        }
      })
  }

  /**
   * Função utilizada quando chamado pelo componente QueroTestar
   */
  async criarNovaContaTeste(
    conta: NewAccountV1Interface
  ) {
    await this.loginService
      .criarNovaContaAvaliacao(conta)
      .subscribe({
        next: resultado => {
          if (resultado.success) {
            this.loaderService.stopLoader();
            Swal.fire(
              resultado.titulo,
              resultado.message,
              'success'
            ).then(() => {
              const dadosLogin = new LoginModel(
                this.formNovaConta.value['email'],
                this.formNovaConta.value['password']
              )
              this.modalAtivo.close();
              this.loginFuncoes.fazerLogin(dadosLogin);
            });
          } else {
            Swal.fire(
              resultado.titulo,
              resultado.message,
              'error'
            );
            this.loaderService.stopLoader();
          }
        },
        error: err => {
          this.loaderService.stopLoader();
          TratamentoErrosHttpErrorResponseService.tratarErro(err);
        }
      })
  }
}
