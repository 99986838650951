export const environment = {
  production: true,

  /**
   * Dados para comunicação com api conversor
   */
  apiConversor: 'https://demoapiofx.epigrafo.com.br',
  //environment dev
  // accessTokenConversor: 'ZXBnX3B4YWdKdHRBVER0YkdMQ0JXY0JVRnlHSHZHUEd5eFl5NDU2OWQzZnB6ZFd2NXl2U1F5dGduNDlCVFBacUQ0VDU1elg5M1N4UU1tODRuSmhxVFFmQTZxTHpEQnU4OEMzNUt4RjIjIw=='
  //environment demo
  accessTokenConversor: 'ZXBnX2pJVndVd0tFdzhrOVBKTW9LWHlQdTFIakZtT01ROUpGdkp5R2loTGJLTExrM3VrbWQ2U2hPOWpoR0hjUU9LUGp0eTNqaVRQU1pUdnA3Y1dTSFNVYkJWclBHSFFtQ1AxREVXTXojIw==',
  
  /**
   * Dados para comunicação com api asaas
   */
  apiAsaas: 'https://asaas1.epigrafo.com',
  accessTokenAsaas: 'R3k0Wmk2THExU3c3TG01TWQyR3Q0VXM3UW42U3ExRWI3VWk4R3UxWXoyRnM4UWU0VHA1WnI2V3kyUXU5SWc0TXo0U2UxRnk2WGs3WWIxQmc1VnQ5QXA2Vm0yWWU3THE2UGQzUw==',
};