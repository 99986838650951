// Components do Sistema
import { Injectable } from "@angular/core";
import { CanActivate, Router } from '@angular/router';
// Utils
import { SecurityUtil } from '../utils/security.util';
// Services
import { UtilsService } from "../utils/utils.service";
import Swal from "sweetalert2";

@Injectable()
export class AuthGuard implements CanActivate {

	constructor(
		private route: Router,
		private utilsService: UtilsService,
	) { }

	/**
	 * Verifica se o token de acesso do usuário está válido.
	 * Se o token não estiver válido, o usuário será redirecionado para a página de login.
	 * Se o token estiver válido, o usuário terá acesso à página solicitada.
	 * @returns {boolean} true se o token estiver válido, false caso contrário.
	 */
	canActivate(): boolean {
		if (!SecurityUtil.getToken()) {
			// Se o token não estiver válido, mostrar uma mensagem de aviso ao usuário
			Swal.fire(
				'Acesso não autorizado!',
				'Ops, você não tem autorização para concluir esta solicitação. Necessário fazer login com uma conta válida.',
				'warning'
			).then(() => {
				// Redirecionar o usuário para a p gina de login
				this.route.navigate(['/auth/login']);
			});
			return false;
		} else {
			// Se o token estiver válido, verificar se o token precisa ser atualizado
			this.utilsService.refreshtoken();
			return true;
		}
	}


	/**
	 * Este método é usado para deslogar o usuário.
	 * Ele limpa os armazenamentos locais e de sessão e redireciona o usuário para a página de login.
	 */
	logout() {
		// Limpar os armazenamentos locais e de sessão
		localStorage.clear();
		sessionStorage.clear();

		// Mostrar uma mensagem de aviso ao usuário
		Swal.fire(
			'Acesso não autorizado!',
			'Ops, você não tem autorização para concluir esta solicitação. Necessário fazer login com uma conta válida.',
			'warning'
		).then(() => {
			// Redirecionar o usuário para a página de login
			this.route.navigate(['/auth/login']);
		});
	}
}
